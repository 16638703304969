import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../styles/ChecklistCountdown.css'; // Add your CSS file for styling

const ChecklistCountdown = () => {
    const { tripId } = useParams();
    const [checklistItems, setChecklistItems] = useState([]);
    const [newItem, setNewItem] = useState('');
    const [predefinedItems] = useState([
        'Book accommodation',
        'Buy travel insurance',
        'Confirm transportation',
        'Finalize guest list',
        'Create itinerary',
        'Assign group responsibilities',
        'Confirm dietary restrictions',
        'Purchase event tickets',
        'Prepare documents',
        'Check luggage requirements'
    ]);

    // Fetch checklist items with useCallback to avoid dependency warning
    const fetchChecklist = useCallback(async () => {
        try {
            const token = localStorage.getItem('accessToken');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            const res = await axios.get(`https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/trips/${tripId}/checklist`, config);
            setChecklistItems(res.data);
        } catch (error) {
            console.error('Error fetching checklist items', error);
        }
    }, [tripId]); // dependency array contains `tripId`

    useEffect(() => {
        fetchChecklist();
    }, [fetchChecklist]); // Call fetchChecklist on component mount

    // Handle adding a custom checklist item
    const handleAddItem = async (e) => {
        e.preventDefault();
        const newChecklistItem = { text: newItem, complete: false };

        try {
            const token = localStorage.getItem('accessToken');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            const res = await axios.post(`https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/trips/${tripId}/checklist`, newChecklistItem, config);
            setChecklistItems([...checklistItems, res.data]);
            setNewItem(''); // Clear input field
        } catch (error) {
            console.error('Error adding checklist item', error);
        }
    };

    // Handle adding predefined checklist items
    const handlePredefinedItemClick = async (item) => {
        const predefinedChecklistItem = { text: item, complete: false };

        try {
            const token = localStorage.getItem('accessToken');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            const res = await axios.post(`https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/trips/${tripId}/checklist`, predefinedChecklistItem, config);
            setChecklistItems([...checklistItems, res.data]);
        } catch (error) {
            console.error('Error adding predefined checklist item', error);
        }
    };

    // Handle toggling checklist item completion status
    const handleToggleComplete = async (itemId) => {
        const updatedItems = checklistItems.map(item =>
            item._id === itemId ? { ...item, complete: !item.complete } : item
        );
        setChecklistItems(updatedItems);

        try {
            const token = localStorage.getItem('accessToken');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            await axios.put(`https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/trips/${tripId}/checklist/${itemId}`, { complete: !updatedItems.complete }, config);
        } catch (error) {
            console.error('Error toggling checklist item complete status', error);
        }
    };

    // Handle deleting a checklist item
    const handleDeleteItem = async (itemId) => {
        const updatedItems = checklistItems.filter(item => item._id !== itemId);
        setChecklistItems(updatedItems);

        try {
            const token = localStorage.getItem('accessToken');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            await axios.delete(`https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/trips/${tripId}/checklist/${itemId}`, config);
        } catch (error) {
            console.error('Error deleting checklist item', error);
        }
    };

    return (
        <div className="checklist-container">
            <h2>Group Trip Checklist</h2>
            
            <form onSubmit={handleAddItem}>
                <input
                    type="text"
                    placeholder="Add a new checklist item"
                    value={newItem}
                    onChange={(e) => setNewItem(e.target.value)}
                />
                <button type="submit">Add Item</button>
            </form>

            <div className="predefined-items">
                <h3>Predefined Checklist Items</h3>
                {predefinedItems.map((item, index) => (
                    <button key={index} onClick={() => handlePredefinedItemClick(item)}>
                        {item}
                    </button>
                ))}
            </div>

            <ul className="checklist-items">
                {checklistItems.map((item) => (
                    <li key={item._id} className={item.complete ? 'complete' : ''}>
                        <span onClick={() => handleToggleComplete(item._id)}>{item.text}</span>
                        <button onClick={() => handleDeleteItem(item._id)}>Delete</button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ChecklistCountdown;
