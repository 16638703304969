import '../styles/TravelerProfiles.css'; // Assuming you will style these components separately
import axios from 'axios';
import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

const TravelerProfiles = () => {
    const { tripId } = useParams();
    const [travelers, setTravelers] = useState([]);

    useEffect(() => {
        const fetchTravelers = async () => {
            const token = localStorage.getItem('accessToken');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            try {
                const response = await axios.get(`https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/trips/${tripId}/travelers`, config);
                setTravelers(response.data || []);
            } catch (error) {
                console.error('Error fetching travelers:', error);
            }
        };

        fetchTravelers();
    }, [tripId]);

    return (
        <div className="traveler-profiles">
            {travelers.map(traveler => (
                <div key={traveler._id} className="profile-card">
                    <div className="profile-header">
                        <img src={traveler.photo} alt={traveler.name} className="profile-photo" />
                        <h4>{traveler.name}</h4>
                    </div>
                    <p><strong>Contact:</strong> {traveler.contact}</p>
                    <p><strong>Assigned Tasks:</strong> {traveler.tasks.join(', ')}</p>
                    <p><strong>Preferences:</strong> {traveler.preferences.join(', ')}</p>
                    <p><strong>Travel Documents:</strong> {traveler.documents.join(', ')}</p>
                </div>
            ))}
        </div>
    );
};

export default TravelerProfiles;
