import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const useFetchData = (tripId) => {
    const [expenses, setExpenses] = useState([]);
    const [guests, setGuests] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const [fetchError, setFetchError] = useState(null);

    const fetchGuestsAndExpenses = useCallback(async () => {
        const token = localStorage.getItem('accessToken');
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        try {
            setIsFetching(true);
            const [guestsRes, tripRes, expensesRes] = await Promise.all([
                axios.get(`https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/trips/${tripId}/guests`, config),
                axios.get(`https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/trips/${tripId}`, config),
                axios.get(`https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/trips/${tripId}/expenses`, config),
            ]);

            const guestsData = guestsRes.data || [];
            const tripCreator = tripRes.data?.createdBy || {}; // Ensure it's defined
            const updatedGuests = [...guestsData, tripCreator].filter(
                (guest, index, self) => guest && index === self.findIndex(g => g?._id === guest?._id)
            );

            setGuests(updatedGuests.filter(Boolean));
            setExpenses(expensesRes.data || []);
            setIsFetching(false);
        } catch (error) {
            console.error('Error fetching data:', error.message);
            setFetchError(error.message || 'Failed to fetch data');
            setIsFetching(false);
        }
    }, [tripId]);

    useEffect(() => {
        if (tripId) {
            fetchGuestsAndExpenses();
        }
    }, [fetchGuestsAndExpenses, tripId]);

    return { expenses, guests, isFetching, fetchError, fetchGuestsAndExpenses };
};

export default useFetchData;
