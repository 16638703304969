import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import '../styles/TripForm.css';

const TripForm = () => {
    const { tripId } = useParams();
    const [formData, setFormData] = useState({
        name: '',
        destination: '',
        startDate: '',
        endDate: '',
        description: '',
        tasks: [], // Ensure tasks are initialized as an empty array
        expenses: [], // Ensure expenses are initialized as an empty array
        itinerary: [], // Ensure itinerary is initialized as an empty array
        milestones: [], // Ensure milestones are initialized as an empty array
        checklistItem: [], // Ensure checklistItems are initialized as an empty array
        guests: [], // Guests initialized as empty array
        createdBy: '', // Add createdBy field to store the current user
    });
    const [guestEmail, setGuestEmail] = useState(''); // Handle guest email input
    const [user, setUser] = useState(null); // To store current logged-in user
    const navigate = useNavigate();
    const isEdit = !!tripId; // Check if it's an edit form based on tripId

    // Fetch current user and initialize the form with user info
    useEffect(() => {
        const fetchUser = async () => {
            const token = localStorage.getItem('accessToken');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            try {
                const res = await axios.get('https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/auth/me', config);
                setUser(res.data);
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    createdBy: res.data._id, // Set the current user as createdBy
                    guests: [{ email: res.data.email }], // Add current user to guests
                }));
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUser();

        if (isEdit) {
            // Fetch trip data to pre-fill the form when editing
            const fetchTripData = async () => {
                const token = localStorage.getItem('accessToken');
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                };
                try {
                    const res = await axios.get(`https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/trips/${tripId}`, config);
                    setFormData(res.data);
                } catch (error) {
                    console.error(error);
                }
            };
            fetchTripData();
        }
    }, [tripId, isEdit]);

    // Handle input field changes
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    // Handle adding guests
    const handleAddGuest = () => {
        if (guestEmail) {
            const newGuest = {
                email: guestEmail,
                name: 'Guest',  // Temporary placeholder or handle name dynamically
            };
            setFormData({
                ...formData,
                guests: [...formData.guests, newGuest]
            });
            setGuestEmail(''); // Clear the input
        }
    };
    

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('accessToken');
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
    
        // Log the formData to see what's being sent
    
        try {
            if (isEdit) {
                await axios.put(`https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/trips/${tripId}`, formData, config);
            } else {
                await axios.post(`https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/trips`, formData, config);
            }
            navigate('/home'); // Redirect to Home screen after submitting
        } catch (error) {
            console.error('Error creating/updating trip:', error);
        }
    };

    return (
        <div className="trip-form-container">
            <h2>{isEdit ? 'Edit Trip' : 'Create New Trip'}</h2>
            <form onSubmit={handleSubmit} className="trip-form">
                <div className="form-group">
                    <label>Trip Name:</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        placeholder="Enter trip name"
                        className="form-input"
                    />
                </div>
                <div className="form-group">
                    <label>Destination:</label>
                    <input
                        type="text"
                        name="destination"
                        value={formData.destination}
                        onChange={handleChange}
                        required
                        placeholder="Enter destination"
                        className="form-input"
                    />
                </div>
                <div className="form-group">
                    <label>Start Date:</label>
                    <input
                        type="date"
                        name="startDate"
                        value={formData.startDate}
                        onChange={handleChange}
                        required
                        className="form-input"
                    />
                </div>
                <div className="form-group">
                    <label>End Date:</label>
                    <input
                        type="date"
                        name="endDate"
                        value={formData.endDate}
                        onChange={handleChange}
                        required
                        className="form-input"
                    />
                </div>
                <div className="form-group">
                    <label>Description:</label>
                    <textarea
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        placeholder="Enter trip description"
                        className="form-textarea"
                    ></textarea>
                </div>

                {/* Guests Section */}
                <div className="form-group">
                    <label>Add Guests (Emails):</label>
                    <div className="guest-input">
                        <input
                            type="email"
                            value={guestEmail}
                            onChange={(e) => setGuestEmail(e.target.value)}
                            placeholder="Enter guest email"
                            className="form-input"
                        />
                        <button type="button" onClick={handleAddGuest} className="form-button">
                            Add Guest
                        </button>
                    </div>
                </div>

                {/* Display the guest list */}
                {formData.guests.length > 0 && (
                    <div className="form-group">
                        <label>Guest List:</label>
                        <ul>
                            {formData.guests.map((guest, index) => (
                                <li key={index}>{guest.email}</li>
                            ))}
                        </ul>
                    </div>
                )}

                <button type="submit" className="form-button">
                    {isEdit ? 'Update Trip' : 'Create Trip'}
                </button>
            </form>
        </div>
    );
};

export default TripForm;
