import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { FiHome, FiClipboard, FiDollarSign, FiMessageSquare } from 'react-icons/fi';
import '../styles/BottomNavbar.css';

const BottomNavbar = () => {
  const { tripId } = useParams();  // Retrieve the tripId from the URL

  return (
    <div className="bottom-navbar">
      <Link to={`/trips/${tripId}`} className="nav-btn">
        <FiHome className="icon" />
        <span>Home</span>
      </Link>
      <Link to={`/trips/${tripId}/itinerary`} className="nav-btn">
        <FiClipboard className="icon" />
        <span>Plans</span>
      </Link>
      <Link to={`/trips/${tripId}/budget`} className="nav-btn">
        <FiDollarSign className="icon" />
        <span>Expenses</span>
      </Link>
      <Link to={`/trips/${tripId}/chat`} className="nav-btn">
        <FiMessageSquare className="icon" />
        <span>Messages</span>
      </Link>
    </div>
  );
};

export default BottomNavbar;
