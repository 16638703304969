import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Signup from './components/Signup';
import Login from './components/Login';
import Home from './components/Home';
import TripForm from './components/TripForm';
import Dashboard from './components/Dashboard';
import GuestList from './components/GuestList';
import BudgetManagement from './components/BudgetManagement';
import Itinerary from './components/Itinerary';
import TaskList from './components/TaskList';
import ChecklistCountdown from './components/ChecklistCountdown';
import TravelerProfiles from './components/TravelerProfiles';
import { IntlProvider } from 'react-intl';
import TripEdit from './components/TripEdit';
import LandingPage from './components/LandingPage';
import Survey from './components/SurveyBuilder';
import ProtectedRoute from './ProtectedRoute';
import FeaturesPage from './components/Features';
import FAQPage from './components/FAQsPage';
import ExpenseList from './components/ExpenseList';

const App = () => {
    const locale = 'en';
    const [currentTripId, setCurrentTripId] = useState(null);  // State to track the current trip

    return (
            <Router>
                <Routes>
                    {/* Public routes */}
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/features" element={<FeaturesPage />} />
                    <Route path="/FAQs" element={<FAQPage />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/login" element={<Login />} />

                    <Route 
                        path="/home" 
                        element={
                            <ProtectedRoute>
                                <Home />
                            </ProtectedRoute>
                        } 
                    />
                    <Route 
                        path="/create-trip" 
                        element={
                            <ProtectedRoute>
                                <TripForm />
                            </ProtectedRoute>
                        } 
                    />
                    <Route 
                        path="/trips/:tripId" 
                        element={
                            <ProtectedRoute>
                                <Dashboard setCurrentTripId={setCurrentTripId} />
                            </ProtectedRoute>
                        } 
                    />
                    <Route 
                        path="/trips/:tripId/guests" 
                        element={
                            <ProtectedRoute>
                                <GuestList />
                            </ProtectedRoute>
                        } 
                    />
                    <Route 
                        path="/trips/:tripId/tasks" 
                        element={
                            <ProtectedRoute>
                                <TaskList />
                            </ProtectedRoute>
                        } 
                    />
                    <Route 
                        path="/trips/:tripId/expenses" 
                        element={
                            <ProtectedRoute>
                                <ExpenseList />
                            </ProtectedRoute>
                        } 
                    />
                    <Route 
                        path="/trips/:tripId/budget" 
                        element={
                            <ProtectedRoute>
                                <BudgetManagement />
                            </ProtectedRoute>
                        } 
                    />
                    <Route 
                        path="/trips/:tripId/itinerary" 
                        element={
                            <ProtectedRoute>
                                <Itinerary />
                            </ProtectedRoute>
                        } 
                    />
                    <Route 
                        path="/trips/:tripId/checklist" 
                        element={
                            <ProtectedRoute>
                                <ChecklistCountdown />
                            </ProtectedRoute>
                        } 
                    />
                    <Route 
                        path="/trips/:tripId/profiles" 
                        element={
                            <ProtectedRoute>
                                <TravelerProfiles />
                            </ProtectedRoute>
                        } 
                    />
          
                    <Route 
                        path="/trips/:tripId/edit" 
                        element={
                            <ProtectedRoute>
                                <TripEdit />
                            </ProtectedRoute>
                        } 
                    />
                    <Route 
                        path="/trips/:tripId/survey" 
                        element={
                            <ProtectedRoute>
                                <Survey />
                            </ProtectedRoute>
                        } 
                    />
                </Routes>
            </Router>
    );
};

export default App;