import React from 'react';
import { useParams } from 'react-router-dom';
import ExpenseForm from './ExpenseForm';
import ExpenseList from './ExpenseList';
import useFetchData from './useFetchData';
import '../styles/BudgetManagement.css';

const BudgetManagement = () => {
  const { tripId } = useParams(); // Get tripId from URL parameters

  const { expenses, guests, isFetching, fetchError, fetchGuestsAndExpenses } = useFetchData(tripId);

  return (
    <div className="budget-management-container">
      <h3>Budget Management</h3>
      <ExpenseForm tripId={tripId} fetchGuestsAndExpenses={fetchGuestsAndExpenses} guests={guests} />
      {fetchError && <p>Error: {fetchError}</p>}
      {expenses.length === 0 && !isFetching && <p>No expenses found for this trip.</p>}
      <ExpenseList tripId={tripId} />
    </div>
  );
};

export default BudgetManagement;
