import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/Home.css'; // Ensure proper styling

const Home = () => {
    const [trips, setTrips] = useState([]);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchTrips = async () => {
            const accessToken = localStorage.getItem('accessToken');  // Consistently using 'accessToken'
        
            if (!accessToken) {
                navigate('/login');  // Redirect to login if no token is found
                return;
            }

            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                };
                
                // Fetch trips with token authorization
                const res = await axios.get('https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/trips', config);
                setTrips(res.data);  // Set trips in state
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    // If unauthorized, redirect to login
                    localStorage.removeItem('accessToken'); // Remove invalid token
                    navigate('/login');
                } else {
                    setError('Failed to fetch trips. Please try again later.');
                }
            }
        };

        fetchTrips();
    }, [navigate]);

    const handleCreateTrip = () => {
        navigate('/create-trip');
    };

    return (
        <div className="home-container">
            <h2>Your Trips</h2>
            <button className="create-trip-btn" onClick={handleCreateTrip}>
                Create New Trip
            </button>
            {error && <p className="error-message">{error}</p>}
            {trips.length > 0 ? (
                <div className="trip-grid">
                    {trips.map((trip) => (
                        <div className="trip-card" key={trip._id}>
                            <Link to={`/trips/${trip._id}`}>
                                <h3>{trip.name}</h3>
                                <p>{trip.destination}</p>
                                <p>{new Date(trip.startDate).toLocaleDateString()} - {new Date(trip.endDate).toLocaleDateString()}</p>
                            </Link>
                        </div>
                    ))}
                </div>
            ) : (
                <p>You have not planned any trips yet. Create a trip above to get started.</p>
            )}
        </div>
    );
};

export default Home;
