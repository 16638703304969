// ExpenseForm.js
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import '../styles/ExpenseForm.css';

const ExpenseForm = ({ tripId, fetchGuestsAndExpenses, guests }) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const modalRef = useRef(null); // To track modal and detect outside clicks
    const [newExpense, setNewExpense] = useState({
        title: '',
        date: new Date().toISOString().split('T')[0], // Default to today's date
        category: 'Miscellaneous',
        amount: '',
        currency: 'USD',
        paidBy: '',
        isReimbursement: false,
        splitMethod: 'evenly',
        splitDetails: {}, // Store guest-specific split details (amount, percentage, shares)
        includeInSplit: {}, // Guests included in the split
        notes: '',
    });
    const [formErrors, setFormErrors] = useState({});
    const [showAdvanced, setShowAdvanced] = useState(false);

    // Close modal when clicking outside of it
    useEffect(() => {
        function handleClickOutside(event) {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setModalOpen(false);
            }
        }
        if (isModalOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isModalOpen]);

    // Load draft from localStorage on component mount
    useEffect(() => {
        const savedDraft = localStorage.getItem('expenseDraft');
        if (savedDraft) {
            setNewExpense(JSON.parse(savedDraft));
        }
    }, []);

    // Auto-save to localStorage whenever newExpense changes
    useEffect(() => {
        localStorage.setItem('expenseDraft', JSON.stringify(newExpense));
    }, [newExpense]);

    const handleExpenseChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;

        setNewExpense((prevExpense) => ({
            ...prevExpense,
            [name]: newValue,
        }));

        // Validate the field
        validateField(name, newValue);
    };

    const validateField = (fieldName, value) => {
        let error = '';
        switch (fieldName) {
            case 'title':
                if (!value) error = 'Title is required.';
                break;
            case 'amount':
                if (!value || value <= 0) error = 'Amount must be greater than 0.';
                break;
            case 'paidBy':
                if (!value) error = 'Select who paid for the expense.';
                break;
            default:
                break;
        }
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: error,
        }));
    };

    const handleSplitChange = (guestId, value) => {
        setNewExpense((prevExpense) => ({
            ...prevExpense,
            splitDetails: {
                ...prevExpense.splitDetails,
                [guestId]: value,
            },
        }));
    };

    const handleIncludeInSplitChange = (guestId) => {
        setNewExpense((prevExpense) => ({
            ...prevExpense,
            includeInSplit: {
                ...prevExpense.includeInSplit,
                [guestId]: !prevExpense.includeInSplit[guestId],
            },
        }));
    };

    const validateForm = () => {
        const errors = {};
        if (!newExpense.title) errors.title = 'Title is required.';
        if (!newExpense.amount || newExpense.amount <= 0) errors.amount = 'Amount must be greater than 0.';
        if (!newExpense.paidBy) errors.paidBy = 'Select who paid for the expense.';
        return errors;
    };

    const handleAddExpense = async (e) => {
        e.preventDefault();
        const errors = validateForm();
        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        const splitResults = calculateSplit();

        try {
            const token = localStorage.getItem('accessToken');
            const config = { headers: { Authorization: `Bearer ${token}` } };
            const expenseData = {
                ...newExpense,
                splitDetails: splitResults,
            };
            await axios.post(
                `https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/trips/${tripId}/expenses`,
                expenseData,
                config
            );
            fetchGuestsAndExpenses();
            setModalOpen(false);
            resetForm();
            // Clear the draft from localStorage after successful submission
            localStorage.removeItem('expenseDraft');
        } catch (error) {
            console.error('Error adding expense:', error);
            alert('Error adding expense');
        }
    };

    const resetForm = () => {
        setNewExpense({
            title: '',
            date: new Date().toISOString().split('T')[0],
            category: 'Miscellaneous',
            amount: '',
            currency: 'USD',
            paidBy: '',
            isReimbursement: false,
            splitMethod: 'evenly',
            splitDetails: {},
            includeInSplit: {},
            notes: '',
        });
        setFormErrors({});
    };

    const calculateSplit = () => {
        const totalAmount = parseFloat(newExpense.amount);
        const includedGuests = Object.keys(newExpense.includeInSplit).filter(
            (guestId) => newExpense.includeInSplit[guestId]
        );
        const splitDetails = {};

        if (newExpense.splitMethod === 'evenly') {
            const amountPerGuest = totalAmount / includedGuests.length;
            includedGuests.forEach((guestId) => {
                splitDetails[guestId] = amountPerGuest;
            });
        } else if (newExpense.splitMethod === 'amount') {
            includedGuests.forEach((guestId) => {
                splitDetails[guestId] = parseFloat(newExpense.splitDetails[guestId]) || 0;
            });
        } else if (newExpense.splitMethod === 'percentage') {
            includedGuests.forEach((guestId) => {
                const percentage = parseFloat(newExpense.splitDetails[guestId]) || 0;
                splitDetails[guestId] = (totalAmount * percentage) / 100;
            });
        } else if (newExpense.splitMethod === 'shares') {
            const totalShares = includedGuests.reduce(
                (acc, guestId) => acc + Number(newExpense.splitDetails[guestId] || 0),
                0
            );
            includedGuests.forEach((guestId) => {
                const shares = parseFloat(newExpense.splitDetails[guestId]) || 0;
                splitDetails[guestId] = (totalAmount * shares) / totalShares;
            });
        }

        return splitDetails;
    };

    return (
        <>
            <button onClick={() => setModalOpen(true)} className="add-expense-btn">
                Add Expense
            </button>

            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="expense-form-modal" ref={modalRef}>
                        <h4 className="expense-form-header">Add New Expense</h4>
                        <div className="expense-form-body">
                            <form onSubmit={handleAddExpense}>
                                {/* Essential Fields */}
                                <div className="form-group">
                                    <label htmlFor="title">Expense Title</label>
                                    <input
                                        type="text"
                                        id="title"
                                        name="title"
                                        placeholder="Enter expense title"
                                        value={newExpense.title}
                                        onChange={handleExpenseChange}
                                        className={formErrors.title ? 'input-error' : ''}
                                        aria-required="true"
                                    />
                                    {formErrors.title && <p className="error">{formErrors.title}</p>}
                                </div>

                                <div className="form-group">
                                    <label htmlFor="amount">Amount</label>
                                    <input
                                        type="number"
                                        id="amount"
                                        name="amount"
                                        placeholder="Enter amount"
                                        value={newExpense.amount}
                                        onChange={handleExpenseChange}
                                        className={formErrors.amount ? 'input-error' : ''}
                                        aria-required="true"
                                    />
                                    {formErrors.amount && <p className="error">{formErrors.amount}</p>}
                                </div>

                                <div className="form-group">
                                    <label htmlFor="paidBy">Paid By</label>
                                    <select
                                        id="paidBy"
                                        name="paidBy"
                                        value={newExpense.paidBy}
                                        onChange={handleExpenseChange}
                                        className={formErrors.paidBy ? 'input-error' : ''}
                                        aria-required="true"
                                    >
                                        <option value="">Select a guest</option>
                                        {guests.map((guest) => (
                                            <option key={guest._id} value={guest._id}>
                                                {guest.name}
                                            </option>
                                        ))}
                                    </select>
                                    {formErrors.paidBy && <p className="error">{formErrors.paidBy}</p>}
                                </div>

                                {/* Toggle for Advanced Options */}
                                <button
                                    type="button"
                                    className="toggle-advanced-btn"
                                    onClick={() => setShowAdvanced(!showAdvanced)}
                                >
                                    {showAdvanced ? 'Hide Advanced Options' : 'Show Advanced Options'}
                                </button>

                                {/* Advanced Fields */}
                                {showAdvanced && (
                                    <>
                                        <div className="form-group">
                                            <label htmlFor="category">Category</label>
                                            <select
                                                id="category"
                                                name="category"
                                                value={newExpense.category}
                                                onChange={handleExpenseChange}
                                            >
                                                <option value="Miscellaneous">Miscellaneous</option>
                                                <option value="Food">Food</option>
                                                <option value="Transport">Transport</option>
                                                <option value="Accommodation">Accommodation</option>
                                                {/* Add more categories as needed */}
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="date">Date</label>
                                            <input
                                                type="date"
                                                id="date"
                                                name="date"
                                                value={newExpense.date}
                                                onChange={handleExpenseChange}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="currency">Currency</label>
                                            <select
                                                id="currency"
                                                name="currency"
                                                value={newExpense.currency}
                                                onChange={handleExpenseChange}
                                            >
                                                <option value="USD">USD</option>
                                                <option value="EUR">EUR</option>
                                                <option value="CAD">CAD</option>
                                                <option value="GBP">GBP</option>
                                                {/* Add more currencies as needed */}
                                            </select>
                                        </div>

                                        {/* Reimbursement Checkbox */}
                                        <div className="form-group">
                                            <label htmlFor="isReimbursement">
                                                <input
                                                    type="checkbox"
                                                    id="isReimbursement"
                                                    name="isReimbursement"
                                                    checked={newExpense.isReimbursement}
                                                    onChange={handleExpenseChange}
                                                />
                                                Is this a reimbursement?
                                            </label>
                                        </div>

                                        {/* Notes */}
                                        <div className="form-group">
                                            <label htmlFor="notes">Notes</label>
                                            <textarea
                                                id="notes"
                                                name="notes"
                                                placeholder="Any additional notes about this expense"
                                                value={newExpense.notes}
                                                onChange={handleExpenseChange}
                                            />
                                        </div>
                                    </>
                                )}

                                {/* Split Method */}
                                <div className="form-group">
                                    <label htmlFor="splitMethod">
                                        Split Method
                                        <span className="tooltip">
                                            &#9432;
                                            <span className="tooltip-text">
                                                Choose how the expense will be divided among guests.
                                            </span>
                                        </span>
                                    </label>
                                    <select
                                        id="splitMethod"
                                        name="splitMethod"
                                        value={newExpense.splitMethod}
                                        onChange={handleExpenseChange}
                                    >
                                        <option value="evenly">Evenly</option>
                                        <option value="amount">By Amount</option>
                                        <option value="percentage">By Percentage</option>
                                        <option value="shares">By Shares</option>
                                    </select>
                                </div>

                                {/* Select Guests for Split */}
                                <div className="split-guests">
                                    <h4>Select Guests to Include in the Split</h4>
                                    {guests.length > 0 ? (
                                        guests.map((guest) => (
                                            <div key={guest._id} className="split-guest-row">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        checked={!!newExpense.includeInSplit[guest._id]}
                                                        onChange={() => handleIncludeInSplitChange(guest._id)}
                                                    />
                                                    {guest.name}
                                                </label>
                                                {newExpense.splitMethod !== 'evenly' &&
                                                    newExpense.includeInSplit[guest._id] && (
                                                        <input
                                                            className="split-input"
                                                            type="number"
                                                            placeholder={
                                                                newExpense.splitMethod === 'percentage'
                                                                    ? 'Percentage'
                                                                    : 'Amount'
                                                            }
                                                            value={newExpense.splitDetails[guest._id] || ''}
                                                            onChange={(e) =>
                                                                handleSplitChange(guest._id, e.target.value)
                                                            }
                                                        />
                                                    )}
                                            </div>
                                        ))
                                    ) : (
                                        <p>No guests available</p>
                                    )}
                                </div>

                                {/* Submit Button */}
                                <button type="submit" className="submit-btn">
                                    Add Expense
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ExpenseForm;
