import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/FeaturesPage.css';

const FeaturesPage = () => {
    return (
        <div className="features-page">
            {/* Header Section */}
            <header className="features-header">
                <div className="logo"><Link to="/">TravelTogether</Link></div>
                <nav className="nav">
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/login">Login</Link></li>
                        <li><Link to="/signup">Sign Up</Link></li>
                    </ul>
                </nav>
            </header>

            {/* Hero Section */}
            <section className="features-hero">
                <h1>Discover Our Key Features</h1>
                <p>TravelTogether is packed with tools to help you plan, organize, and enjoy your trips with ease.</p>
            </section>

            {/* Features Section */}
            <section className="features-list">
                <div className="feature-item">
                    <img src="group-planning-icon.png" alt="Group Planning" />
                    <h3>Group Trip Planning</h3>
                    <p>Coordinate seamlessly with friends and family to organize your dream trip together.</p>
                </div>
                <div className="feature-item">
                    <img src="shared-itinerary-icon.png" alt="Shared Itineraries" />
                    <h3>Shared Itineraries</h3>
                    <p>Collaborate and create detailed itineraries so everyone is in the loop and on the same page.</p>
                </div>
                <div className="feature-item">
                    <img src="budget-management-icon.png" alt="Budget Management" />
                    <h3>Budget Management</h3>
                    <p>Track expenses and manage your budget with easy-to-use tools for splitting costs.</p>
                </div>
                <div className="feature-item">
                    <img src="real-time-chat-icon.png" alt="Real-time Chat" />
                    <h3>Real-time Chat</h3>
                    <p>Stay connected with your group via real-time chat during the planning and while traveling.</p>
                </div>
                <div className="feature-item">
                    <img src="polls-icon.png" alt="Polls & Surveys" />
                    <h3>Polls & Surveys</h3>
                    <p>Get group input easily with integrated polls and surveys for planning activities and destinations.</p>
                </div>
                <div className="feature-item">
                    <img src="task-management-icon.png" alt="Task Management" />
                    <h3>Task Management</h3>
                    <p>Assign and track tasks to ensure everything is taken care of before and during your trip.</p>
                </div>
            </section>

            {/* Call to Action Section */}
            <section className="features-cta">
                <h2>Start Planning Your Next Adventure</h2>
                <p>Sign up today and make your next trip your best one yet.</p>
                <Link to="/signup" className="cta-button">Sign Up Now</Link>
            </section>

            {/* Footer */}
            <footer className="features-footer">
                <p>© 2024 TravelTogether. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default FeaturesPage;
