import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Pie } from 'react-chartjs-2';
import '../styles/ExpenseList.css'; 
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ExpenseSettlement from './ExpenseSettlement';
import TopNavbar from './TopNavbar';
import BottomNavbar from './BottomNavbar';

ChartJS.register(ArcElement, Tooltip, Legend);

const ExpenseList = () => {
    const [showSettlement, setShowSettlement] = useState(false);
    const { tripId } = useParams();
    const [expenses, setExpenses] = useState([]);
    const [guests, setGuests] = useState([]);
    const [reimbursements, setReimbursements] = useState([]);
    const [chartData, setChartData] = useState(null);
    const [isFetching, setIsFetching] = useState(true);
    const [fetchError, setFetchError] = useState(null);
    const [showReimbursementForm, setShowReimbursementForm] = useState(false); // Track reimbursement form visibility
    const [reimbursement, setReimbursement] = useState({
        payer: '',
        payee: '',
        amount: 0,
    });
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [guestsPerPage] = useState(10);

    const fetchGuestsAndExpenses = useCallback(async () => {
        const token = localStorage.getItem('accessToken');
        const config = { headers: { Authorization: `Bearer ${token}` } };

        try {
            setIsFetching(true);
            const [guestsRes, expensesRes, tripRes] = await Promise.all([
                axios.get(`https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/trips/${tripId}/guests`, config),
                axios.get(`https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/trips/${tripId}/expenses`, config),
                axios.get(`https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/trips/${tripId}`, config),
            ]);
            console.log(guestsRes.data)
            setGuests(Array.isArray(guestsRes.data) ? guestsRes.data : []);
            setExpenses(Array.isArray(expensesRes.data.expenses) ? expensesRes.data.expenses : []);
            setReimbursements(Array.isArray(tripRes.data.reimbursements) ? tripRes.data.reimbursements : []);
            setIsFetching(false);

            updateChartData(expensesRes.data.expenses);
        } catch (error) {
            console.error('Error fetching data:', error.message);
            setFetchError(error.message || 'Failed to fetch data');
            setIsFetching(false);
        }
    }, [tripId]);

    useEffect(() => {
        if (tripId) {
            fetchGuestsAndExpenses();
        }
    }, [tripId, fetchGuestsAndExpenses]);

    const handleDeleteExpense = async (expenseId) => {
        if (!window.confirm('Are you sure you want to delete this expense?')) return;

        try {
            const token = localStorage.getItem('accessToken');
            const config = { headers: { Authorization: `Bearer ${token}` } };
            await axios.delete(`https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/trips/${tripId}/expenses/${expenseId}`, config);
            fetchGuestsAndExpenses(); // Refresh the expense list after deleting
        } catch (error) {
            console.error('Error deleting expense:', error);
            alert('Failed to delete expense');
        }
    }

    const calculateBalances = () => {
        const guestMap = guests.reduce((acc, guest) => {
            acc[guest._id] = { name: guest.name, owes: {}, isOwed: {} };
            return acc;
        }, {});

        expenses.forEach(expense => {
            const payerId = expense.paidBy;
            const splitDetails = expense.splitDetails || {};

            Object.entries(splitDetails).forEach(([guestId, amountOwed]) => {
                if (guestId !== payerId) {
                    guestMap[guestId].owes[payerId] = (guestMap[guestId].owes[payerId] || 0) + amountOwed;
                    guestMap[payerId].isOwed[guestId] = (guestMap[payerId].isOwed[guestId] || 0) + amountOwed;
                }
            });
        });

        reimbursements.forEach(reimbursement => {
            const { payer, payee, amount } = reimbursement;
            guestMap[payer].owes[payee] -= amount;
            guestMap[payee].isOwed[payer] -= amount;
        });

        return guestMap;
    };

    const guestBalances = calculateBalances();

    const applyReimbursement = async () => {
        const { payer, payee, amount } = reimbursement;

        if (!payer || !payee || amount <= 0) {
            alert('Please fill out all fields');
            return;
        }

        const token = localStorage.getItem('accessToken');
        const config = { headers: { Authorization: `Bearer ${token}` } };

        try {
            await axios.post(
                `https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/trips/${tripId}/reimbursements`,
                { payer, payee, amount },
                config
            );

            fetchGuestsAndExpenses();
            setReimbursement({ payer: '', payee: '', amount: 0 });
            setShowReimbursementForm(false); // Hide the form after submission
        } catch (error) {
            console.error('Error adding reimbursement:', error);
            alert('Failed to add reimbursement');
        }
    };

    const updateChartData = (expenseData) => {
        if (!expenseData || !Array.isArray(expenseData)) return;

        const categories = {};

        expenseData.forEach((expense) => {
            if (!categories[expense.category]) {
                categories[expense.category] = 0;
            }
            categories[expense.category] += parseFloat(expense.amount);
        });

        const labels = Object.keys(categories);
        const data = Object.values(categories);

        setChartData({
            labels,
            datasets: [
                {
                    data,
                    backgroundColor: ['#ff6384', '#36a2eb', '#ffce56'],
                },
            ],
        });
    };

    const filteredGuests = guests.filter(guest =>
        guest.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const indexOfLastGuest = currentPage * guestsPerPage;
    const indexOfFirstGuest = indexOfLastGuest - guestsPerPage;
    const currentGuests = filteredGuests.slice(indexOfFirstGuest, indexOfLastGuest);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    if (isFetching) return <div className="spinner">Loading...</div>;
    if (fetchError) return <div className="error-banner">Error: {fetchError}</div>;

    return (
        <div className="expenses-overview">
            <TopNavbar pageTitle="Expenses" />

            <h3>Balances Summary</h3>

            <input
                type="text"
                placeholder="Search guests..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input"
            />

            {currentGuests.length > 0 ? (
                currentGuests.map(guest => (
                    <div key={guest._id} className="guest-balance-card">
                        <h4>{guest.name}</h4>
                        <details>
                            <summary>View Details</summary>
                            {Object.entries(guestBalances[guest._id].owes).map(([to, amount]) => (
                                <p key={to}>
                                    Owes {guests.find(g => g._id === to)?.name}: ${amount.toFixed(2)}
                                </p>
                            ))}
                            {Object.entries(guestBalances[guest._id].isOwed).map(([from, amount]) => (
                                <p key={from}>
                                    Is owed by {guests.find(g => g._id === from)?.name}: ${amount.toFixed(2)}
                                </p>
                            ))}
                        </details>
                    </div>
                ))
            ) : (
                <p>No guests available.</p>
            )}

            <div className="pagination">
                {Array.from({ length: Math.ceil(filteredGuests.length / guestsPerPage) }, (_, index) => (
                    <button key={index + 1} onClick={() => paginate(index + 1)}>
                        {index + 1}
                    </button>
                ))}
            </div>

            {/* Toggle Reimbursement Form */}
            <button 
                onClick={() => setShowReimbursementForm(!showReimbursementForm)}
                className="submit-btn"
            >
                {showReimbursementForm ? 'Hide Reimbursement Form' : 'Add Reimbursement'}
            </button>

            {/* Conditional rendering of Reimbursement form */}
            {showReimbursementForm && (
                <form onSubmit={(e) => { e.preventDefault(); applyReimbursement(); }}>
                    <label>Payer:</label>
                    <select
                        value={reimbursement.payer}
                        onChange={(e) => setReimbursement({ ...reimbursement, payer: e.target.value })}
                        required
                        className="form-select"
                    >
                        <option value="">Select Payer</option>
                        {guests.map((guest) => (
                            <option key={guest._id} value={guest._id}>
                                {guest.name || 'Unknown Guest'}
                            </option>
                        ))}
                    </select>

                    <label>Payee:</label>
                    <select
                        value={reimbursement.payee}
                        onChange={(e) => setReimbursement({ ...reimbursement, payee: e.target.value })}
                        required
                        className="form-select"
                    >
                        <option value="">Select Payee</option>
                        {guests.map((guest) => (
                            <option key={guest._id} value={guest._id}>
                                {guest.name || 'Unknown Guest'}
                            </option>
                        ))}
                    </select>

                    <label>Amount:</label>
                    <input
                        type="number"
                        value={reimbursement.amount}
                        onChange={(e) => setReimbursement({ ...reimbursement, amount: e.target.value })}
                        required
                        className="form-input"
                    />

                    <button type="submit" className="submit-btn">Add Reimbursement</button>
                </form>
            )}

            <h3>Expenses</h3>
            {expenses.length > 0 ? (
                expenses.map(expense => (
                    <div className="expense-card" key={expense._id}>
                        <h4>{expense.title}</h4>
                        <p>Date: {new Date(expense.date).toLocaleDateString()}</p>
                        <p>Amount: ${expense.amount.toFixed(2)}</p>
                        <p>Category: {expense.category}</p>
                        <p>Paid by: {guests.find(g => g._id === expense.paidBy)?.name || 'Unknown'}</p>
                        <h5>Split Details:</h5>
                        {Object.entries(expense.splitDetails).map(([guestId, amountOwed]) => (
                            <p key={guestId}>
                                {guests.find(g => g._id === guestId)?.name || 'Unknown'} owes ${amountOwed.toFixed(2)}
                            </p>
                        ))}
                        <button onClick={() => setShowSettlement(!showSettlement)}>
                            {showSettlement ? 'Hide Payment Settlements' : 'Manage Payment Settlements'}
                        </button>
                        {showSettlement && <ExpenseSettlement tripId={tripId} />}
                        <button 
                            className="delete-expense-btn" 
                            onClick={() => handleDeleteExpense(expense._id)}
                        >
                            Delete
                        </button>
                    </div>
                ))
            ) : (
                <p>No expenses found for this trip.</p>
            )}

            {chartData && (
                <div className="chart-container">
                    <h4>Expense Breakdown</h4>
                    <Pie data={chartData} />
                </div>
            )}

            <div className="pagination">
                {Array.from({ length: Math.ceil(expenses.length / guestsPerPage) }, (_, index) => (
                    <button key={index + 1} onClick={() => paginate(index + 1)}>
                        {index + 1}
                    </button>
                ))}
            </div>
            <BottomNavbar />
        </div>
    );
};

export default ExpenseList;
