import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { getAccessToken } from '../src/utils/auth';  // Adjusted to reference accessToken utility

const ProtectedRoute = ({ children }) => {
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkAuth = async () => {
            const accessToken = getAccessToken();

            if (!accessToken) {
                setIsAuthorized(false);
                setIsLoading(false);
                return;
            }

            try {
                const response = await axios.get('https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/auth/me', {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                if (response.status === 200) {
                    setIsAuthorized(true);
                }
            } catch (error) {
                setIsAuthorized(false);
            } finally {
                setIsLoading(false);
            }
        };

        checkAuth();
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!isAuthorized) {
        return <Navigate to="/login" />;
    }

    return children;
};

export default ProtectedRoute;
