import React, { useState } from 'react';
import axios from 'axios';
import { setAccessToken, getRefreshToken } from '../utils/auth';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        try {
            const res = await axios.post('https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/auth/login', formData);
            const { accessToken, refreshToken } = res.data;

            // Store the tokens consistently
            setAccessToken(accessToken);
            getRefreshToken(refreshToken);

            console.log('Login successful, navigating to /home.');
            navigate('/home');
        } catch (err) {
            setError('Invalid email or password.');
        }
    };

    return (
        <div className="login-container">
            <form onSubmit={handleSubmit}>
                <h2>Login</h2>
                <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
                <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                />
                {error && <p className="error-message">{error}</p>}
                <button type="submit">Log In</button>
            </form>
        </div>
    );
};

export default Login;
