import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/Itinerary.css'; 
import TopNavbar from './TopNavbar';
import BottomNavbar from './BottomNavbar';

const Itinerary = () => {
    const { tripId } = useParams();
    const [itinerary, setItinerary] = useState([]);
    const [guests, setGuests] = useState([]); // State for guest list
    const [newItem, setNewItem] = useState({
        type: '',
        title: '',
        description: '',
        date: '',
        time: '',
        location: '',
        cost: '',
        attendees: [], // State for attendees
        isRecurring: false,
        recurrence: ''
    });
    const [tripDetails, setTripDetails] = useState({ startDate: '', endDate: '' });

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('accessToken');
            const config = { headers: { Authorization: `Bearer ${token}` } };
            try {
                const res = await axios.get(`https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/trips/${tripId}`, config);
                setItinerary(res.data.itinerary);
                setTripDetails({ startDate: res.data.startDate, endDate: res.data.endDate });
                setGuests(res.data.guests); // Fetch the guest list
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [tripId]);

    const handleAddItem = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('accessToken');
        const config = { headers: { Authorization: `Bearer ${token}` } };

        try {
            const res = await axios.post(`https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/trips/${tripId}/itinerary`, newItem, config);
            setItinerary(res.data);
            setNewItem({ 
                type: '', 
                title: '', 
                description: '', 
                date: '', 
                time: '', 
                location: '', 
                cost: '', 
                isRecurring: false, 
                recurrence: '', 
                attendees: [] 
            });
        } catch (error) {
            console.error('Error creating itinerary item:', error);
        }
    };

    const handleItemChange = (e) => {
        const { name, value, type, checked } = e.target;
        setNewItem((prevItem) => ({
            ...prevItem,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleAttendeeChange = (e) => {
        const { value, checked } = e.target;
        setNewItem((prevItem) => ({
            ...prevItem,
            attendees: checked
                ? [...prevItem.attendees, value]
                : prevItem.attendees.filter(att => att !== value)
        }));
    };

    const handleDeleteItem = async (itemId) => {
        const token = localStorage.getItem('accessToken');
        const config = { headers: { Authorization: `Bearer ${token}` } };
        try {
            const res = await axios.delete(`https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/trips/${tripId}/itinerary/${itemId}`, config);
            setItinerary(res.data);
        } catch (error) {
            console.error(error);
        }
    };

    // Function to get icon based on the type of itinerary item
    const getIcon = (type) => {
        switch (type) {
            case 'Flight': return '✈️';
            case 'Hotel': return '🏨';
            case 'Activity': return '🎉';
            case 'Meal': return '🍽️';
            case 'Transport': return '🚌';
            case 'Tour': return '🗺️';
            case 'Meeting': return '📅';
            case 'Shopping': return '🛍️';
            case 'Beach': return '🏖️';
            case 'Fitness': return '🏋️';
            case 'Cultural Event': return '🎭';
            case 'Spa': return '💆';
            default: return '📍';
        }
    };

    // Sorting itinerary by date and time
    const sortedItinerary = itinerary.sort((a, b) => {
        const dateA = new Date(`${a.date}T${a.time}`);
        const dateB = new Date(`${b.date}T${b.time}`);
        return dateA - dateB;
    });

    return (
        <div className="itinerary-container">
               <TopNavbar pageTitle="Itinerary" />

            <h3 className="itinerary-heading">Itinerary</h3>
            <form className="itinerary-form" onSubmit={handleAddItem}>
                <div className="form-row">
                    <select className="itinerary-input" name="type" value={newItem.type} onChange={handleItemChange}>
                        <option value="">Select Type</option>
                        <option value="Flight">Flight</option>
                        <option value="Hotel">Hotel</option>
                        <option value="Activity">Activity</option>
                        <option value="Meal">Meal</option>
                        <option value="Transport">Transport</option>
                        <option value="Tour">Tour</option>
                        <option value="Meeting">Meeting</option>
                        <option value="Shopping">Shopping</option>
                        <option value="Beach">Beach</option>
                        <option value="Fitness">Fitness</option>
                        <option value="Cultural Event">Cultural Event</option>
                        <option value="Spa">Spa</option>
                    </select>
                    <input className="itinerary-input" type="text" name="title" placeholder="Event Title" value={newItem.title} onChange={handleItemChange} />
                </div>
                <div className="form-row">
                    <input className="itinerary-input" type="text" name="description" placeholder="Description" value={newItem.description} onChange={handleItemChange} />
                    <DatePicker className="itinerary-input" selected={newItem.date} onChange={date => setNewItem({ ...newItem, date })} placeholderText="Select Date" />
                    <input className="itinerary-input" type="time" name="time" value={newItem.time} onChange={handleItemChange} />
                </div>
                <div className="form-row">
                    <input className="itinerary-input" type="text" name="location" placeholder="Location" value={newItem.location} onChange={handleItemChange} />
                </div>
                <div className="form-row attendees-checkboxes">
                    <h4>Attendees</h4>
                    {guests.map(guest => (
                        <label key={guest._id}>
                            <input type="checkbox" value={guest._id} onChange={handleAttendeeChange} checked={newItem.attendees.includes(guest._id)} />
                            {guest.name}
                        </label>
                    ))}
                </div>
                <input 
                    type="number" 
                    name="cost" 
                    placeholder="Cost (optional)" 
                    value={newItem.cost} 
                    onChange={handleItemChange} 
                    min="0"
                    className="itinerary-input"
                />
                <button className="itinerary-button" type="submit">Add Itinerary Item</button>
            </form>

            <ul className="itinerary-list">
                {sortedItinerary.map(item => (
                    <li key={item._id} className="itinerary-item">
                        <div className="itinerary-item-content">
                            <span>{getIcon(item.type)} {item.title} - {item.description} - {item.location}</span>
                            <span>Date: {new Date(item.date).toLocaleDateString()} {item.time}</span>
                            <span>Attendees: {item.attendees?.map(id => guests.find(g => g._id === id)?.name).join(', ') || 'None'}</span>
                            <span>Cost: ${item.cost || '0.00'}</span>
                        </div>
                        <button className="itinerary-delete-button" onClick={() => handleDeleteItem(item._id)}>Delete</button>
                    </li>
                ))}
            </ul>
            <BottomNavbar />
        </div>
    );
};

export default Itinerary;
