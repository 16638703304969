import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import { FiEdit, FiTrash2, FiUserPlus } from 'react-icons/fi';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import '../styles/GuestList.css';
import TopNavbar from './TopNavbar';
import BottomNavbar from './BottomNavbar';

Modal.setAppElement('#root');

const GuestList = () => {
  const { tripId } = useParams();
  const [guests, setGuests] = useState([]);
  const [filteredGuests, setFilteredGuests] = useState([]);
  const [editGuest, setEditGuest] = useState(null);
  const [isFetching, setIsFetching] = useState(true);
  const [fetchError, setFetchError] = useState(null);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [newGuest, setNewGuest] = useState({
    name: '',
    email: '',
    status: 'invited',
    isCollaborator: false,
    numberOfAdults: 1,
    numberOfChildren: 0,
    dietaryRestrictions: '',
    roomRequirements: 1,
    roomAssignment: '',
    specialRequests: '',
    group: '',
  });
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [availableGroups, setAvailableGroups] = useState([]);
  const [multipleNames, setMultipleNames] = useState('');

  // Load saved form data from localStorage on component mount
  useEffect(() => {
    const savedGuestData = JSON.parse(localStorage.getItem('newGuest'));
    if (savedGuestData) {
      setNewGuest(savedGuestData);
    }
  }, []);

  // Save form data to localStorage whenever the guest form is updated
  useEffect(() => {
    localStorage.setItem('newGuest', JSON.stringify(newGuest));
  }, [newGuest]);

  // Fetch guests
  useEffect(() => {
    const fetchGuests = async () => {
      const token = localStorage.getItem('accessToken');
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        setIsFetching(true);
        const response = await axios.get(
          `https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/trips/${tripId}/guests`,
          config
        );
        if (Array.isArray(response.data)) {
          setGuests(response.data);
          setFilteredGuests(response.data);
        }
        setIsFetching(false);
      } catch (error) {
        setFetchError(error.message);
        setIsFetching(false);
      }
    };
    fetchGuests();
  }, [tripId]);

  // Real-time form validation
  const validateGuestForm = (guest) => {
    const errors = {};
    if (!guest.name) errors.name = 'Name is required';
    if (guest.numberOfAdults < 1) errors.numberOfAdults = 'At least one adult is required';
    return errors;
  };

  // Form error display
  const displayFormErrors = (field) => formErrors[field] && <p className="error">{formErrors[field]}</p>;

  // Progressive disclosure (show room assignments if more than 1 room is needed)
  const showRoomAssignment = newGuest.roomRequirements > 1;

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    setFilteredGuests(
      guests.filter(
        (guest) =>
          guest.name.toLowerCase().includes(term) || guest.email.toLowerCase().includes(term)
      )
    );
  };

  const handleGuestChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewGuest({
      ...newGuest,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleUpdateGuest = async (e) => {
    e.preventDefault();
    const errors = validateGuestForm(editGuest);
    if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        return;
    }

    const token = localStorage.getItem('accessToken');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    try {
        setLoading(true);
        const res = await axios.put(`https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/trips/${tripId}/guests/${editGuest._id}`, editGuest, config);
        setGuests(guests.map(g => (g._id === editGuest._id ? res.data : g)));
        setFilteredGuests(guests.map(g => (g._id === editGuest._id ? res.data : g)));
        setEditGuest(null);
        setFormErrors({});
        setEditModalOpen(false);
        toast.success('Guest updated successfully!');
    } catch (error) {
        console.error(error);
        toast.error('Failed to update guest');
    } finally {
        setLoading(false);
    }
};


  const handleDeleteGuest = async (guestId) => {
    if (!window.confirm('Are you sure you want to delete this guest?')) return;

    const token = localStorage.getItem('accessToken');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    try {
        const res = await axios.delete(`https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/trips/${tripId}/guests/${guestId}`, config);
        setGuests(guests.filter(g => g._id !== guestId));
        setFilteredGuests(filteredGuests.filter(g => g._id !== guestId)); // Update the filtered list
        toast.success('Guest deleted successfully!');
    } catch (error) {
        console.error(error);
        toast.error('Failed to delete guest');
    }
};

  const handleAddGuest = async (e) => {
    e.preventDefault();
    const errors = validateGuestForm(newGuest);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    setLoading(true);
    const token = localStorage.getItem('accessToken');
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await axios.post(
        `https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/trips/${tripId}/guests`,
        newGuest,
        config
      );
      setGuests([...guests, res.data]);
      setFilteredGuests([...guests, res.data]);
      setNewGuest({
        name: '',
        email: '',
        status: 'invited',
        isCollaborator: false,
        numberOfAdults: 1,
        numberOfChildren: 0,
        dietaryRestrictions: '',
        roomRequirements: 1,
        roomAssignment: '',
        specialRequests: '',
        group: '',
      });
      localStorage.removeItem('newGuest'); // Clear saved data on successful form submission
      setFormErrors({});
      setAddModalOpen(false);
      toast.success('Guest added successfully!');
    } catch (error) {
      toast.error('Failed to add guest');
    } finally {
      setLoading(false);
    }
  };


  const renderTooltip = (text) => (
    <span className="tooltip">
      ⓘ
      <span className="tooltip-text">{text}</span>
    </span>
  );

  return (
    <div className="guest-list-container">
      <TopNavbar pageTitle="Guests" />
      <ToastContainer />

      <div className="guest-list-header">
        <h3>Guest List</h3>
        <p>Total Guests: {filteredGuests.length}</p>

        <label>Filter by Group</label>
        <select
          value={selectedGroup}
          onChange={(e) => setSelectedGroup(e.target.value)}
        >
          <option value="">All Groups</option>
          {availableGroups.map((group, index) => (
            <option key={index} value={group}>
              {group}
            </option>
          ))}
        </select>

        <input
          type="text"
          placeholder="Search by name or email..."
          value={searchTerm}
          onChange={handleSearch}
          className="guest-search-input"
        />

        <button className="btn-add-guest" onClick={() => setAddModalOpen(true)}>
          <FiUserPlus className="icon-add" /> Add New Guest
        </button>
      </div>

      <div className="guest-list">
        {Array.isArray(filteredGuests) && filteredGuests.length > 0 ? (
          filteredGuests.map((guest, index) => (
            <div key={guest._id || index} className="guest-card">
              <div className="guest-info">
                <h4>{guest.name}</h4>
                <p>Email: {guest.email}</p>
                <p>Status: {guest.status}</p>
                <p>Adults: {guest.numberOfAdults}</p>
                <p>Children: {guest.numberOfChildren}</p>
                <p>
                  Dietary Restrictions: {guest.dietaryRestrictions || 'None'}{' '}
                  {renderTooltip('Mention any food allergies or preferences.')}
                </p>
                <p>Room Requirements: {guest.roomRequirements || 'None'}</p>
                <p>Special Requests: {guest.specialRequests || 'None'}</p>
                <p>Group: {guest.group || 'None'}</p>
              </div>

              <div className="guest-actions">
                <button className="btn-edit" onClick={() => setEditModalOpen(true)}>
                  <FiEdit /> Edit
                </button>
                <button
                  className="btn-delete"
                  onClick={() => handleDeleteGuest(guest._id)}
                >
                  <FiTrash2 /> Delete
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>No guests found</p>
        )}
      </div>

      {/* Add Guest Modal */}
      <Modal isOpen={isAddModalOpen} onRequestClose={() => setAddModalOpen(false)} contentLabel="Add Guest Modal">
        <h4>Add New Guest</h4>
        <form onSubmit={handleAddGuest}>
          <label>
            Name
            {renderTooltip('Full name of the guest.')}
          </label>
          <input
            type="text"
            name="name"
            placeholder="Guest Name"
            value={newGuest.name}
            onChange={handleGuestChange}
            required
          />
          {displayFormErrors('name')}

          <label>Email</label>
          <input
            type="email"
            name="email"
            placeholder="Guest Email"
            value={newGuest.email}
            onChange={handleGuestChange}
            required
          />

          <label>Status</label>
          <select name="status" value={newGuest.status} onChange={handleGuestChange}>
            <option value="invited">Invited</option>
            <option value="declined">Declined</option>
            <option value="accepted">Accepted</option>
          </select>

          <label>Collaborator</label>
          <input
            type="checkbox"
            name="isCollaborator"
            checked={newGuest.isCollaborator}
            onChange={handleGuestChange}
          />

          <label>
            Number of Adults
            {renderTooltip('Number of adults attending.')}
          </label>
          <input
            type="number"
            name="numberOfAdults"
            value={newGuest.numberOfAdults}
            onChange={handleGuestChange}
            min="1"
            required
          />
          {displayFormErrors('numberOfAdults')}

          <label>
            Number of Children
            {renderTooltip('Number of children attending.')}
          </label>
          <input
            type="number"
            name="numberOfChildren"
            value={newGuest.numberOfChildren}
            onChange={handleGuestChange}
            min="0"
          />

          <label>
            Dietary Restrictions
            {renderTooltip('Mention any food allergies or preferences.')}
          </label>
          <input
            type="text"
            name="dietaryRestrictions"
            placeholder="Dietary Restrictions"
            value={newGuest.dietaryRestrictions}
            onChange={handleGuestChange}
          />

          <label>
            Room Requirements
            {renderTooltip('Number of rooms needed.')}
          </label>
          <input
            type="number"
            name="roomRequirements"
            value={newGuest.roomRequirements}
            onChange={handleGuestChange}
          />

          {/* Progressive Disclosure: Show room assignment only if more than 1 room */}
          {showRoomAssignment && (
            <div>
              <label>Room Assignments</label>
              <textarea
                name="roomAssignments"
                placeholder="Enter names of people in each room (comma separated)"
                value={newGuest.roomAssignments}
                onChange={handleGuestChange}
              />
            </div>
          )}

          <label>
            Special Requests
            {renderTooltip('Any special accommodations needed.')}
          </label>
          <textarea
            name="specialRequests"
            placeholder="Special Requests"
            value={newGuest.specialRequests}
            onChange={handleGuestChange}
          ></textarea>

          <label>Group</label>
          <input
            type="text"
            name="group"
            placeholder="Group (e.g., Family, Friends)"
            value={newGuest.group}
            onChange={handleGuestChange}
          />

          <button type="submit" disabled={loading}>
            {loading ? 'Submitting...' : 'Add Guest'}
          </button>
          <button type="button" onClick={() => setAddModalOpen(false)}>
            Cancel
          </button>
        </form>
      </Modal>

      {/* Edit Guest Modal */}
      {editGuest && (
        <Modal isOpen={isEditModalOpen} onRequestClose={() => setEditModalOpen(false)} contentLabel="Edit Guest Modal">
          <h4>Edit Guest</h4>
          <form onSubmit={handleUpdateGuest}>
            <label>
              Name
              {renderTooltip('Full name of the guest.')}
            </label>
            <input
              type="text"
              name="name"
              value={editGuest.name}
              onChange={handleGuestChange}
              required
            />
            {displayFormErrors('name')}

            <label>Email</label>
            <input
              type="email"
              name="email"
              value={editGuest.email}
              onChange={handleGuestChange}
              required
            />

            <label>Status</label>
            <select name="status" value={editGuest.status} onChange={handleGuestChange}>
              <option value="invited">Invited</option>
              <option value="declined">Declined</option>
              <option value="accepted">Accepted</option>
            </select>

            <label>Collaborator</label>
            <input
              type="checkbox"
              name="isCollaborator"
              checked={editGuest.isCollaborator}
              onChange={handleGuestChange}
            />

            <label>Number of Adults</label>
            <input
              type="number"
              name="numberOfAdults"
              value={editGuest.numberOfAdults}
              onChange={handleGuestChange}
              min="1"
              required
            />

            <label>Number of Children</label>
            <input
              type="number"
              name="numberOfChildren"
              value={editGuest.numberOfChildren}
              onChange={handleGuestChange}
              min="0"
            />

            <label>Dietary Restrictions</label>
            <input
              type="text"
              name="dietaryRestrictions"
              value={editGuest.dietaryRestrictions}
              onChange={handleGuestChange}
            />

            <label>Room Requirements</label>
            <input
              type="number"
              name="roomRequirements"
              value={editGuest.roomRequirements}
              onChange={handleGuestChange}
            />

            {editGuest.roomRequirements > 1 && (
              <div>
                <label>Room Assignments</label>
                <textarea
                  name="roomAssignments"
                  value={editGuest.roomAssignments}
                  onChange={handleGuestChange}
                />
              </div>
            )}

            <label>Special Requests</label>
            <textarea
              name="specialRequests"
              value={editGuest.specialRequests}
              onChange={handleGuestChange}
            ></textarea>

            <label>Group</label>
            <input
              type="text"
              name="group"
              value={editGuest.group}
              onChange={handleGuestChange}
            />

            <button type="submit" disabled={loading}>
              {loading ? 'Saving...' : 'Save Changes'}
            </button>
            <button type="button" onClick={() => setEditModalOpen(false)}>
              Cancel
            </button>
          </form>
        </Modal>
      )}
      <BottomNavbar />
    </div>
  );
};

export default GuestList;
