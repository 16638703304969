import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/FAQPage.css';

const FAQPage = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    // Toggle active FAQ
    const toggleFAQ = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null); // Close active FAQ
        } else {
            setActiveIndex(index); // Open selected FAQ
        }
    };

    const faqs = [
        {
            question: 'What is TravelTogether?',
            answer: 'TravelTogether is a group trip planning app that helps you organize, plan, and share your travel experiences with friends and family.',
        },
        {
            question: 'How do I invite others to a trip?',
            answer: 'You can invite others by adding their email to the guest list within the app. They will receive an invitation to join the trip.',
        },
        {
            question: 'How do I manage trip expenses?',
            answer: 'Our app allows you to track and split expenses with ease using the Budget Management feature.',
        },
        {
            question: 'Can I share the trip itinerary?',
            answer: 'Yes! The app allows you to create and share detailed itineraries with your travel companions.',
        },
        {
            question: 'Is the app free to use?',
            answer: 'Yes, TravelTogether offers a free version with essential features. We also offer premium options for additional features.',
        },
    ];

    return (
        <div className="faq-page">
            {/* Header Section */}
            <header className="faq-header">
                <div className="logo">TravelTogether</div>
                <nav className="nav">
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/login">Login</Link></li>
                        <li><Link to="/signup">Sign Up</Link></li>
                    </ul>
                </nav>
            </header>

            {/* FAQ Section */}
            <section className="faq-section">
                <h1>Frequently Asked Questions</h1>
                <div className="faq-list">
                    {faqs.map((faq, index) => (
                        <div
                            key={index}
                            className={`faq-item ${activeIndex === index ? 'active' : ''}`}
                            onClick={() => toggleFAQ(index)}
                        >
                            <div className="faq-question">
                                {faq.question}
                                <span className="faq-icon">
                                    {activeIndex === index ? '-' : '+'}
                                </span>
                            </div>
                            {activeIndex === index && (
                                <div className="faq-answer">
                                    <p>{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </section>

            {/* Call to Action Section */}
            <section className="faq-cta">
                <h2>Still have questions?</h2>
                <p>If you can’t find the answer to your question, feel free to <Link to="/contact">contact us</Link>.</p>
            </section>

            {/* Footer */}
            <footer className="faq-footer">
                <p>© 2024 TravelTogether. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default FAQPage;
