import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ExpenseSettlement = ({ tripId }) => {
  const [guests, setGuests] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchGuestsAndExpenses();
  }, [tripId]);

  const fetchGuestsAndExpenses = async () => {
    try {
      const token = localStorage.getItem('accessToken');
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const API_BASE_URL = 'https://traveltogetherserver-d0151f4382b4.herokuapp.com';

      const guestsRes = await axios.get(`${API_BASE_URL}/api/trips/${tripId}/guests`, config);
      console.log('Guests data:', guestsRes.data);
      const expensesRes = await axios.get(`${API_BASE_URL}/api/trips/${tripId}/expenses`, config);
      console.log('Expenses data:', expensesRes.data);

      setGuests(Array.isArray(guestsRes.data) ? guestsRes.data : []);
      setExpenses(Array.isArray(expensesRes.data.expenses) ? expensesRes.data.expenses : []);
    } catch (error) {
      console.error('Error fetching data:', error.message);
      setGuests([]);
      setExpenses([]);
    } finally {
      setIsLoading(false);
    }
  };

  const calculateGuestBalance = (guestId) => {
    let totalOwed = 0;

    expenses.forEach(expense => {
      const splitDetails = expense.splitDetails || {};
      if (splitDetails[guestId]) {
        totalOwed += splitDetails[guestId];
      }
    });

    return totalOwed;
  };

  const settleBalance = async (guestId) => {
    try {
      const token = localStorage.getItem('accessToken');
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const API_BASE_URL = 'https://traveltogetherserver-d0151f4382b4.herokuapp.com';

      await axios.post(`${API_BASE_URL}/api/trips/${tripId}/guests/${guestId}/settle`, {}, config);
      fetchGuestsAndExpenses(); // Refetch data to update balances
    } catch (error) {
      console.error('Error settling balance:', error.message);
    }
  };

  if (isLoading) {
    return <div>Loading payment settlements...</div>;
  }

  if (!Array.isArray(guests) || guests.length === 0) {
    return <div>No guests available.</div>;
  }

  return (
    <div>
      <h3>Guests and Amounts Owed</h3>
      <ul>
        {guests.map(guest => (
          <li key={guest._id}>
            {guest.name} owes ${calculateGuestBalance(guest._id).toFixed(2)}
            <button onClick={() => settleBalance(guest._id)}>Settle</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ExpenseSettlement;
