import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { motion, AnimatePresence } from 'framer-motion';
import '../styles/TaskList.css'; // Your CSS file for task list styling
import TopNavbar from './TopNavbar';
import BottomNavbar from './BottomNavbar';

// Set app element for accessibility
Modal.setAppElement('#root');

const TaskList = () => {
    const { tripId } = useParams();
    const [tasks, setTasks] = useState([]);
    const [guests, setGuests] = useState([]); // To hold list of guests for assigning tasks
    const [newTask, setNewTask] = useState({
        title: '',
        description: '',
        priority: 'medium',
        dueDate: null,
        status: 'to-do',
        assignee: ''
    });
    const [editTask, setEditTask] = useState(null);
    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    // Fetch tasks and guests function (memoized with useCallback)
    const fetchTasksAndGuests = useCallback(async () => {
        try {
            const token = localStorage.getItem('accessToken');
            const config = { headers: { Authorization: `Bearer ${token}` } };

            // Fetch tasks
            const tasksRes = await axios.get(`https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/trips/${tripId}/tasks`, config);
            const parsedTasks = tasksRes.data.map(task => ({
                ...task,
                dueDate: task.dueDate ? new Date(task.dueDate) : null
            }));
            setTasks(parsedTasks);

            // Fetch guests to assign tasks
            const guestsRes = await axios.get(`https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/trips/${tripId}/guests`, config);
            setGuests(guestsRes.data);
        } catch (error) {
            console.error('Error fetching tasks and guests:', error);
            toast.error('Failed to fetch tasks or guests');
        }
    }, [tripId]);

    // Fetch tasks and guests on initial load
    useEffect(() => {
        fetchTasksAndGuests();
    }, [fetchTasksAndGuests]);

    const handleTaskChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (editTask) {
            setEditTask({
                ...editTask,
                [name]: type === 'checkbox' ? checked : value,
            });
        } else {
            setNewTask({
                ...newTask,
                [name]: type === 'checkbox' ? checked : value,
            });
        }
    };

    // Add new task
    const handleAddTask = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const token = localStorage.getItem('accessToken');
            const config = { headers: { Authorization: `Bearer ${token}` } };
            const res = await axios.post(`https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/trips/${tripId}/tasks`, newTask, config);
            const newTaskWithDate = { ...res.data, dueDate: new Date(res.data.dueDate) };

            setTasks([...tasks, newTaskWithDate]);
            setNewTask({
                title: '',
                description: '',
                priority: 'medium',
                dueDate: null,
                status: 'to-do',
                assignee: ''
            });
            setShowAddForm(false);
            toast.success('Task added successfully!');
        } catch (error) {
            console.error('Error adding task:', error);
            toast.error('Failed to add task');
        } finally {
            setLoading(false);
        }
    };

    // Update task and refresh the task list
    const handleUpdateTask = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const token = localStorage.getItem('accessToken');
            const config = { headers: { Authorization: `Bearer ${token}` } };
            await axios.put(`https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/trips/${tripId}/tasks/${editTask._id}`, editTask, config);

            // Fetch the updated task list to reflect changes
            await fetchTasksAndGuests();

            setEditTask(null);
            setShowEditForm(false);
            toast.success('Task updated successfully!');
        } catch (error) {
            console.error('Error updating task:', error);
            toast.error('Failed to update task');
        } finally {
            setLoading(false);
        }
    };

    // Delete task
    const handleDeleteTask = async (taskId) => {
        if (!window.confirm('Are you sure you want to delete this task?')) return;

        try {
            const token = localStorage.getItem('accessToken');
            const config = { headers: { Authorization: `Bearer ${token}` } };
            await axios.delete(`https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/trips/${taskId}/tasks`, config);

            // Fetch the updated task list after deletion
            await fetchTasksAndGuests();

            toast.success('Task deleted successfully!');
        } catch (error) {
            console.error('Error deleting task:', error);
            toast.error('Failed to delete task');
        }
    };

    // Filter tasks by search query
    const filteredTasks = tasks.filter(task => {
        const title = task.title || ''; // Fallback to empty string if title is undefined
        const searchTermLower = searchQuery.toLowerCase(); // Convert the search term to lowercase
        return title.toLowerCase().includes(searchTermLower); // Ensure title exists before calling toLowerCase()
    });

    return (
        <div className="task-list-container">
                              <TopNavbar pageTitle="Tasks" />

            <ToastContainer />
            <h3>Task List</h3>
            <button onClick={() => setShowAddForm(true)}>Add New Task</button>

            <div className="task-controls">
                <input
                    type="text"
                    placeholder="Search tasks..."
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                />
            </div>

            {showAddForm && (
                <form onSubmit={handleAddTask} className="task-form">
                    <h4>Add New Task</h4>
                    <input
                        type="text"
                        name="title"
                        placeholder="Task Title"
                        value={newTask.title}
                        onChange={handleTaskChange}
                        required
                    />
                    <textarea
                        name="description"
                        placeholder="Task Description"
                        value={newTask.description}
                        onChange={handleTaskChange}
                    ></textarea>

                    <div className="form-group">
                        <label>Priority:</label>
                        <select name="priority" value={newTask.priority} onChange={handleTaskChange}>
                            <option value="high">High</option>
                            <option value="medium">Medium</option>
                            <option value="low">Low</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label>Due Date:</label>
                        <DatePicker
                            selected={newTask.dueDate}
                            onChange={date => setNewTask({ ...newTask, dueDate: date })}
                            placeholderText="Select Due Date"
                        />
                    </div>

                    <div className="form-group">
                        <label>Assignee:</label>
                        <select name="assignee" value={newTask.assignee} onChange={handleTaskChange}>
                            <option value="">Unassigned</option>
                            {guests.map(guest => (
                                <option key={guest._id} value={guest._id}>
                                    {guest.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <button type="submit" disabled={loading}>
                        {loading ? 'Saving...' : 'Add Task'}
                    </button>
                    <button type="button" onClick={() => setShowAddForm(false)}>Cancel</button>
                </form>
            )}

            {showEditForm && editTask && (
                <form onSubmit={handleUpdateTask} className="task-form">
                    <h4>Edit Task</h4>
                    <input
                        type="text"
                        name="title"
                        placeholder="Task Title"
                        value={editTask.title}
                        onChange={handleTaskChange}
                        required
                    />
                    <textarea
                        name="description"
                        placeholder="Task Description"
                        value={editTask.description}
                        onChange={handleTaskChange}
                    ></textarea>

                    <div className="form-group">
                        <label>Priority:</label>
                        <select name="priority" value={editTask.priority} onChange={handleTaskChange}>
                            <option value="high">High</option>
                            <option value="medium">Medium</option>
                            <option value="low">Low</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label>Assignee:</label>
                        <select name="assignee" value={editTask.assignee} onChange={handleTaskChange}>
                            <option value="">Unassigned</option>
                            {guests.map(guest => (
                                <option key={guest._id} value={guest._id}>
                                    {guest.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <DatePicker
                        selected={editTask.dueDate}
                        onChange={date => setEditTask({ ...editTask, dueDate: date })}
                        placeholderText="Select Due Date"
                    />
                    <button type="submit" disabled={loading}>{loading ? 'Saving...' : 'Save Changes'}</button>
                    <button type="button" onClick={() => setShowEditForm(false)}>Cancel</button>
                </form>
            )}

            <ul className="task-list">
                <AnimatePresence>
                    {filteredTasks.map((task) => (
                        <motion.li
                            key={task._id}  // Ensure this key is unique and consistent
                            className="task-item improved-task-item"
                        >
                            <div className="task-header">
                                <h4 className="task-title">{task.title}</h4>
                                <div className={`priority-indicator ${task.priority ? task.priority.toLowerCase() : 'medium'}`}>
                                    {task.priority || 'Medium'}
                                </div>
                            </div>
                            <p className="task-description">{task.description || 'No description provided'}</p>
                            
                            <div className="task-details">
                                <p><strong>Due Date:</strong> {task.dueDate ? task.dueDate.toDateString() : 'None'}</p>
                                <p><strong>Status:</strong> {task.status}</p>
                                <p><strong>Priority:</strong> {task.priority || 'Medium'}</p>
                                <p><strong>Assignee:</strong> {task.assignee ? guests.find(guest => guest._id === task.assignee)?.name : 'Unassigned'}</p>
                            </div>

                            <div className="task-actions">
                                <button className="edit-btn" onClick={() => {
                                    setEditTask(task);
                                    setShowEditForm(true);
                                }}>Edit</button>
                                <button className="delete-btn" onClick={() => handleDeleteTask(task._id)}>Delete</button>
                            </div>
                        </motion.li>
                    ))}
                </AnimatePresence>
            </ul>
            <BottomNavbar/>
        </div>
    );
};

export default TaskList;
