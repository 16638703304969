import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FiBell, FiArrowLeft } from 'react-icons/fi'; // Import both icons
import '../styles/TopNavbar.css';

const TopNavbar = ({ pageTitle }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="top-navbar">
      <button className="back-button" onClick={handleBack}>
        <FiArrowLeft /> {/* Back arrow icon */}
      </button>
      <div className="page-title">{pageTitle}</div>
      <div className="notification-icon">
        <FiBell />
      </div>
    </div>
  );
};

export default TopNavbar;
