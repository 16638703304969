import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/SurveyBuilder.css';
import TopNavbar from './TopNavbar';
import BottomNavbar from './BottomNavbar';

// Updated predefined questions with new categories and helpful questions
const predefinedQuestions = [
    {
        category: 'General Trip Preferences',
        questions: [
            'What kind of destination are you interested in?',
            'How long do you want the trip to be?',
            'What type of accommodation do you prefer?',
            'What time of year is best for you?',
            'What is your primary goal for this trip?',
            'Are there any specific destinations you’ve always wanted to visit?',
            'What’s your preferred travel group size (small, medium, large)?'
        ]
    },
    {
        category: 'Travel Logistics',
        questions: [
            'How do you prefer to travel (plane, car, train)?',
            'How flexible are you with travel dates?',
            'Are you comfortable with sharing accommodation?',
            'What is your budget for accommodation per night?',
            'Do you need assistance with transportation arrangements (airport pickup, car rental)?'
        ]
    },
    {
        category: 'Activities and Interests',
        questions: [
            'What type of activities do you enjoy (adventure, relaxation, sightseeing)?',
            'Are you interested in attending any special events or festivals during the trip?',
            'What pace do you prefer for activities (fast, moderate, slow)?',
            'Are there specific landmarks or attractions you want to visit?',
            'Are you interested in trying local cuisine or sticking to familiar foods?'
        ]
    },
    {
        category: 'Family and Group Considerations',
        questions: [
            'Are you bringing children on the trip?',
            'Do you have any dietary restrictions or preferences?',
            'Will you need any special accommodations?',
            'Is there anyone in the group with specific needs (e.g., mobility issues)?',
            'Will you need family-friendly activities during the trip?'
        ]
    },
    {
        category: 'Packing & Essentials',
        questions: [
            'Do you need help preparing a packing list?',
            'Do you have any specific items you must bring?',
            'Are there any essential documents you need to arrange (passports, visas)?',
            'Do you need suggestions for travel gear or clothing?'
        ]
    },
    {
        category: 'Health & Safety',
        questions: [
            'Do you have any medical conditions we should be aware of?',
            'Do you need any special medical supplies or equipment?',
            'How concerned are you about safety in the destination country?',
            'Would you like travel insurance recommendations?',
            'Do you need assistance finding healthcare facilities during the trip?'
        ]
    },
    {
        category: 'Budget',
        questions: [
            'What is your total budget for this trip?',
            'How much do you expect to spend on meals per day?',
            'How much are you willing to spend on activities and excursions?',
            'Are you interested in budget-saving tips for this trip?',
            'How much of your budget is allocated for transportation?'
        ]
    },
    {
        category: 'Accessibility Needs',
        questions: [
            'Do you need wheelchair accessibility?',
            'Do you require any special travel accommodations for mobility?',
            'Are you traveling with someone who needs accessibility support?',
            'Would you prefer accessible tours and attractions?'
        ]
    },
    // Add more categories and questions as needed...
];

const Survey = () => {
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [customQuestions, setCustomQuestions] = useState([]);
    const [newCustomQuestion, setNewCustomQuestion] = useState('');
    const [emails, setEmails] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(true);
    const navigate = useNavigate();
    const [emailInput, setEmailInput] = useState('');

    // Handle selection of predefined questions (click to select)
    const handleSelectQuestion = (category, question) => {
        const questionKey = `${category}: ${question}`;
        if (selectedQuestions.includes(questionKey)) {
            setSelectedQuestions(selectedQuestions.filter(q => q !== questionKey));
        } else {
            setSelectedQuestions([...selectedQuestions, questionKey]);
        }
    };

    // Handle custom question input and addition
    const handleAddCustomQuestion = (e) => {
        if (newCustomQuestion.trim() && e.key === 'Enter') {
            setCustomQuestions([...customQuestions, newCustomQuestion]);
            setNewCustomQuestion('');
        }
    };

    // Handle email input and addition
    const handleEmailChange = (e) => {
        setEmailInput(e.target.value);
    };

    const addEmail = (e) => {
        if (emailInput.trim() && e.key === 'Enter') {
            setEmails([...emails, emailInput]);
            setEmailInput('');
        }
    };

    const removeEmail = (emailToRemove) => {
        setEmails(emails.filter(email => email !== emailToRemove));
    };

    // Handle survey submission
    const handleSubmitSurvey = async () => {
        const survey = {
            questions: [...selectedQuestions, ...customQuestions],
            emails
        };

        try {
            await axios.post('https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/survey/send', survey);
            alert('Survey sent successfully!');
            navigate('/home');
        } catch (error) {
            console.error('Error sending survey:', error);
            alert('Failed to send survey');
        }
    };

    return (
        <div className="survey-container">
                  <TopNavbar pageTitle="Surveys" />

            <h2>Create Your Trip Planning Survey</h2>
            <p>Select predefined questions or add your own custom questions to build your trip survey:</p>

            <h3>Predefined Questions</h3>
            {predefinedQuestions.map((section, index) => (
                <details key={index} className="category-section">
                    <summary>{section.category}</summary>
                    <ul>
                        {section.questions.map((question, idx) => (
                            <li
                                key={idx}
                                className={selectedQuestions.includes(`${section.category}: ${question}`) ? 'selected-question' : 'unselected-question'}
                                onClick={() => handleSelectQuestion(section.category, question)}
                                style={{
                                    cursor: 'pointer',
                                    backgroundColor: selectedQuestions.includes(`${section.category}: ${question}`) ? '#f0f0f0' : 'transparent',
                                    padding: '10px',
                                    borderRadius: '5px',
                                    marginBottom: '5px',
                                    transition: 'background-color 0.3s'
                                }}
                            >
                                {question}
                            </li>
                        ))}
                    </ul>
                </details>
            ))}

            <h3>Add Your Own Questions</h3>
            <div>
                <input
                    type="text"
                    value={newCustomQuestion}
                    onChange={(e) => setNewCustomQuestion(e.target.value)}
                    onKeyPress={handleAddCustomQuestion}
                    placeholder="Add your custom question and press Enter"
                />
            </div>

            {customQuestions.length > 0 && (
                <div>
                    <h4>Your Custom Questions:</h4>
                    <ul>
                        {customQuestions.map((q, idx) => (
                            <li key={idx}>{q}</li>
                        ))}
                    </ul>
                </div>
            )}

            <h3>Email the Survey</h3>
            <p>Enter multiple emails, pressing Enter to add each:</p>
            <input
                type="text"
                value={emailInput}
                onChange={handleEmailChange}
                onKeyPress={addEmail}
                placeholder="Enter email addresses and press Enter"
            />
            <div className="email-tags">
                {emails.map((email, idx) => (
                    <span key={idx} className="email-tag">
                        {email}
                        <button onClick={() => removeEmail(email)}>×</button>
                    </span>
                ))}
            </div>

            {/* Live Preview Section */}
            {previewVisible && (
                <div className="survey-preview">
                    <h4>Survey Preview</h4>
                    <ul>
                        {[...selectedQuestions, ...customQuestions].map((q, idx) => (
                            <li key={idx}>{q}</li>
                        ))}
                    </ul>
                </div>
            )}

            <button onClick={handleSubmitSurvey}>Send Survey</button>
            <BottomNavbar />
        </div>

    );
};

export default Survey;
