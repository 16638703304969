import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/LandingPage.css';

const LandingPage = () => {
    return (
        <div className="landing-page">
            {/* Header Section */}
            <header className="header">
                <div className="logo">TravelTogether</div>
                <nav className="nav">
                    <ul>
                        <li><Link to="/features">Features</Link></li>
                        <li><Link to="/login">Login</Link></li>
                        <li><Link to="/signup" className="signup-button">Sign Up</Link></li>
                    </ul>
                </nav>
            </header>

            {/* Hero Section */}
            <section className="hero full-width">
                <div className="hero-content">
                    <h1>Make Group Travel Planning Effortless</h1>
                    <p>Collaborate, budget, and plan—all in one place. TravelTogether makes group trip planning simple and enjoyable.</p>
                    <div className="cta-buttons">
                        <Link to="/signup" className="cta-button">Start Your Trip Plan Today</Link>
                    </div>
                </div>
            </section>

            {/* Detailed Use Cases Section */}
            <section className="use-cases full-width">
                <h2>Perfect for Any Group Adventure</h2>
                <div className="use-case-list">
                    <div className="use-case-item">
                        <h3>Family Reunions</h3>
                        <p>Organize family reunions effortlessly with activities everyone will love.</p>
                    </div>
                    <div className="use-case-item">
                        <h3>Friends' Getaways</h3>
                        <p>Plan and split expenses with friends for an unforgettable experience.</p>
                    </div>
                    <div className="use-case-item">
                        <h3>Corporate Retreats</h3>
                        <p>Coordinate team-building retreats with ease, from planning to execution.</p>
                    </div>
                    <div className="use-case-item">
                        <h3>Bachelor/Bachelorette Parties</h3>
                        <p>Make planning stress-free for special celebrations with your closest friends.</p>
                    </div>
                </div>
            </section>

            {/* Travel Tips and Advice Section */}
            <section className="travel-tips full-width">
                <h2>Travel Planning Made Easy</h2>
                <div className="tips-list">
                    <div className="tip-item">
                        <h3>Tips for Budgeting as a Group</h3>
                        <p>Learn how to save money and manage expenses efficiently while traveling with a group.</p>
                    </div>
                    <div className="tip-item">
                        <h3>Packing Lists for Different Destinations</h3>
                        <p>Check out our packing guides for beach, mountain, or city adventures.</p>
                    </div>
                    <div className="tip-item">
                        <h3>Managing Group Dynamics</h3>
                        <p>Keep everyone happy and handle group dynamics like a pro during your travels.</p>
                    </div>
                </div>
            </section>

            {/* Feature Deep-Dive Section */}
            <section className="feature-deep-dive full-width">
                <h2>What Makes TravelTogether Stand Out</h2>
                <div className="feature-list">
                    <div className="feature-item">
                        <h3>Real-Time Budget Tracking</h3>
                        <p>Track expenses in real time and split costs with ease, ensuring everyone stays on budget.</p>
                    </div>
                    <div className="feature-item">
                        <h3>Collaborative Itinerary Building</h3>
                        <p>Build itineraries that everyone can contribute to, making sure all preferences are included.</p>
                    </div>
                    <div className="feature-item">
                        <h3>Group Chat and Notifications</h3>
                        <p>Keep everyone in the loop with built-in chat and notifications, all within the app.</p>
                    </div>
                </div>
            </section>

            {/* Social Proof Section (Placeholder) */}
            <section className="user-stories full-width">
                <h2>Hear from Travelers Like You</h2>
                <div className="user-story-placeholder">
                    <div className="story-item">
                        <p>"TravelTogether made organizing our family reunion so much easier!" - Sarah M.</p>
                    </div>
                    <div className="story-item">
                        <p>"I love how we can share itineraries and keep track of our budget in one place." - John D.</p>
                    </div>
                </div>
            </section>

            {/* CTA Section */}
            <section className="cta-section full-width">
                <h2>Ready to simplify your next group adventure?</h2>
                <p>Sign up today and make your next group trip the best one yet.</p>
                <Link to="/signup" className="cta-button-large">Join Now</Link>
            </section>

           {/* Footer */}
           <footer className="footer full-width">
                <div className="footer-links">
                    <Link to="/FAQs">FAQs</Link>
                    <Link to="/contact">Contact</Link>
                </div>
                <div className="social-media">
                    <a href="https://www.instagram.com/traveltogetherapp/" target="_blank" rel="noopener noreferrer">
                        <img src="instagram-icon.png" alt="Instagram" />
                    </a>
                </div>
                <p>© 2024 TravelTogether. All rights reserved.</p>
            </footer>
        </div>
    );
};


export default LandingPage;
