import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/Dashboard.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import TopNavbar from './TopNavbar';
import BottomNavbar from './BottomNavbar';
const Dashboard = () => {
    const { tripId } = useParams();
    const [tripData, setTripData] = useState(null);
    const navigate = useNavigate();
    const [guestRole, setGuestRole] = useState('');
    const [startDateCountdown, setStartDateCountdown] = useState('');

    useEffect(() => {
        const fetchTripData = async () => {
            const token = localStorage.getItem('accessToken');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            try {
                const res = await axios.get(`https://traveltogetherserver-d0151f4382b4.herokuapp.com/api/trips/${tripId}`, config);
                setTripData(res.data);
                setGuestRole(res.data.guestRole);

                // Calculate countdown to the start date
                const startDate = moment(res.data.startDate);
                const currentDate = moment();
                const countdown = startDate.diff(currentDate, 'days');
                setStartDateCountdown(countdown >= 0 ? countdown : 'Trip started');
            } catch (error) {
                console.error(error);
            }
        };
        fetchTripData();
    }, [tripId]);

    // Function to navigate to the trip form for editing details
    const handleEditTrip = () => {
        navigate(`/trips/${tripId}/edit`);
    };

    return (
        <div className="dashboard">
 <TopNavbar pageTitle={tripData?.name} /> 
            <header className="dashboard-header">
                <h1>{tripData?.name}</h1>
                <p>{tripData?.destination}</p>
                {/* Countdown Display */}
                <div className="countdown">
                    <p>{startDateCountdown === 'Trip started' ? 'Trip has started' : `Starts in ${startDateCountdown} days`}</p>
                </div>
                <button className="change-trip-btn" onClick={handleEditTrip}>
                    Change Trip Details
                </button>
            </header>

            <div className="dashboard-grid">
                <Link to={`/trips/${tripId}/tasks`} className="dashboard-box blue">
                    Tasks
                </Link>

                <Link to={`/trips/${tripId}/guests`} className="dashboard-box pink">
                    Guest List
                </Link>

                <Link to={`/trips/${tripId}/budget`} className="dashboard-box yellow">
                    Budget Management
                </Link>

                <Link to={`/trips/${tripId}/itinerary`} className="dashboard-box purple">
                    Itinerary
                </Link>

                <Link to={`/trips/${tripId}/survey`} className="dashboard-box purple">
                    Polls and Surveys
                </Link>
            </div>

            <ToastContainer />
            <BottomNavbar />
        </div>
    );
};

export default Dashboard;
